import React, { useEffect } from "react";
import uuid from "react-uuid";
import { isExpired, decodeToken } from "react-jwt";
import { Route, Routes, useSearchParams } from "react-router-dom";
import { Provider } from "react-redux";
import { CookiesProvider } from "react-cookie";

import store from "./redux/store";
import { ColorModeProvider } from "./context/ColorMode";
import { SnackBarProvider } from "./context/SnackBar";
import { UserProvider } from "./context/UserContext";

import AppContainer from "./components/micro/AppContainer";

import LoginPage from "./pages/Login";
import RegisterPage from "./pages/Register";
import ForgotPasswordPage from "./pages/ForgotPassword";
import ResetPasswordPage from "./pages/ResetPassword";
import ProfilePage from "./pages/Profile";
import CancelPlanPage from "./pages/CancelPlan";
import CanceledPage from "./pages/CanceledPlan";
import PaymentMethodPage from "./pages/PaymentMethod";
import LandingPage from "./pages/LandingPage";
import WaitlistPage from "./pages/Waitlist";
import DawLoginPage from "./pages/Daw/Login";
import ProtectedRoute from "./utils/routes/ProtectedRoute";
import PublicRoute from "./utils/routes/PublicRoute";

import "./main.css";
import NotFoundPage from "./pages/NotFound";
import PlansPage from "./pages/Plans";
import Landing from "./pages/Landing";
import UpgradeSuccessPage from "./pages/UpgradeSuccess";
import UniversalDownload from "./pages/Download/Universal";
import DownloadPage from "./pages/Download";
import Reminder from "./pages/EmailReminder";
import LandingPageAlt from "./pages/HardwareLandingPage";
import Checkout from "./pages/Checkout";

function App() {
  const [searchParams] = useSearchParams();
  const clearCacheData = () => {
    caches.keys().then((names) => {
      names.forEach((name) => {
        caches.delete(name);
      });
    });
  };

  useEffect(() => {
    clearCacheData();

    try {
      if (!localStorage.getItem("user_settings")) {
        fetch("https://api.ipregistry.co/?key=wo4s5eduw8p4w1w0")
          .then(function (response) {
            return response.json();
          })
          .then(function (payload) {
            const user_settings = {
              location_lat: payload?.location?.latitude,
              location_long: payload?.location?.longitude,
              platform: `${payload?.user_agent?.device?.type} web: ${payload?.user_agent?.device?.brand} (${payload?.user_agent?.device?.name})`,
              device_family: `${payload?.user_agent?.device?.brand}`,
              country: payload?.location?.country?.name,
              city: payload?.location?.city,
              region: payload?.location?.region?.name,
              carrier: payload?.connection?.organization,
              os_name: `${payload?.user_agent?.os?.name}`,
              os_version: payload?.user_agent?.os?.version,
              language: `${payload?.location?.language?.code}`,
              ip: payload?.ip,
            };
            localStorage.setItem(
              "user_settings",
              JSON.stringify(user_settings),
            );
          });
      }
    } catch (error) {
      console.log(error);
    }

    const utmParams = {
      utm_source: searchParams.get("utm_source") || "",
      utm_medium: searchParams.get("utm_medium") || "",
      utm_campaign: searchParams.get("utm_campaign") || "",
      utm_content: searchParams.get("utm_content") || "",
      link_domain: searchParams.get("link_domain") || "",
      link_slug: searchParams.get("link_slug") || "",
    };
    if (
      utmParams.utm_source ||
      utmParams.utm_medium ||
      utmParams.utm_campaign ||
      utmParams.utm_content ||
      utmParams.link_domain ||
      utmParams.link_slug
    ) {
      localStorage.setItem("utm_params", JSON.stringify(utmParams));
    }

    if (!localStorage.getItem("user_id")) {
      localStorage.setItem("user_id", uuid());
    }
  }, []);

  return (
    <CookiesProvider>
      <ColorModeProvider>
        <UserProvider>
          <SnackBarProvider>
            <Provider store={store}>
              <Routes>
                <Route
                  path={"/"}
                  element={
                    <PublicRoute>
                      <AppContainer />
                    </PublicRoute>
                  }
                >
                  <Route index element={<Landing />} />
                  <Route path={"landing"} element={<LandingPage />} />
                  <Route path={"hardware"} element={<LandingPageAlt />} />
                  <Route path={"checkout"} element={<Checkout />} />
                  <Route path={"login"} element={<LoginPage />} />
                  <Route
                    path={"onboarding/:stepNum?/:refId?"}
                    element={<RegisterPage />}
                  />
                  <Route
                    path={"reset-password"}
                    element={<ResetPasswordPage />}
                  />
                  <Route path={"waitlist"} element={<WaitlistPage />} />
                </Route>
                <Route
                  path={"/account"}
                  element={
                    <ProtectedRoute>
                      <AppContainer />
                    </ProtectedRoute>
                  }
                >
                  <Route path={"hardware"} element={<LandingPageAlt />} />
                  <Route path={"checkout"} element={<Checkout />} />

                  <Route path={"download"} element={<DownloadPage />} />
                  <Route path={"plans"} element={<PlansPage />} />
                  <Route index element={<ProfilePage />} />
                  <Route path={"canceled-plan"} element={<CanceledPage />} />
                  <Route
                    path={"payment-method"}
                    element={<PaymentMethodPage />}
                  />
                  <Route
                    path={"upgrade-success"}
                    element={<UpgradeSuccessPage />}
                  />
                </Route>
                <Route path="daw" >
                  <Route path="login" element={<DawLoginPage />} />
                </Route>
                <Route path="download" element={<UniversalDownload />} />
                <Route path="download-intel" element={<UniversalDownload />} />
                <Route path="download-arm" element={<UniversalDownload />} />
                <Route path="*" element={<NotFoundPage />} />{" "}
                <Route path="reminder" element={<Reminder />} />
                {/* Global catch-all route */}
              </Routes>
            </Provider>
          </SnackBarProvider>
        </UserProvider>
      </ColorModeProvider>
    </CookiesProvider>
  );
}

export default App;
